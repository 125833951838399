<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Home" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Label *"
                  v-model="label"
                  :messages="['H1']"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                  @input="delete errors.label"
                />
                <va-input
                  label="Second"
                  v-model="second"
                  :error="!!errors.second"
                  :error-messages="errors.second"
                  @input="delete errors.second"
                />
                <va-input
                  label="Section title"
                  v-model="sectionTitle"
                  :error="!!errors.section_title"
                  :error-messages="errors.section_title"
                  @input="delete errors.section_title"
                />
                <va-card class="mb-3" title="Image">
                  <div v-if="imageExisting">
                    <a style="display: block;" target="_blank" :href="imageExisting"><img style="max-width: 400px;" :src="imageExisting"></a>
                    <div><va-button small color="danger" @click="imageExisting = deleteSingleImage(imageId) ? '' : imageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.image"
                    v-model="imageNew"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(`image`, 'FounderPageImage', `FounderPage[image]`, imageNew[0], 'backend\\modules\\pages\\models\\FounderPage', 1, '')">Завантажити</va-button>
                  <div v-if="errors.image" style="color: red;">{{ errors.image[0] }}</div>
                </va-card>
                <va-input
                  type="textarea"
                  label="Description *"
                  v-model="description"
                  :error="!!errors.description"
                  :error-messages="errors.description"
                  @input="delete errors.description"
                />
                <va-button
                  @click="submit()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <div class="row">
                  <div class="flex xs6">
                    <va-select
                      v-model="selectedBlock"
                      :options="blocks"
                      no-clear
                    >
                    </va-select>
                  </div>
                  <div class="flex xs6">
                    <va-button style="margin-bottom: 10px;" small @click="addInput">Додати</va-button>
                  </div>
                </div>
                <draggable v-model="content" handle=".handle">
                  <div
                    class="mb-4"
                    v-for="(contentItem, index) in content"
                    :key="index"
                  >
                    <va-card stripe="info" :title="contentItem.type">
                      <div class="text-right">
                        <i class="fa fa-align-justify handle"></i>
                      </div>
                      <div v-if="contentItem.type === 'biography'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error="!!errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`TwoTextBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="First title (left)"
                          v-model="contentItem.first_title"
                          @input="delete errors[`BiographyBlock[${index}][first_title]`]"
                          :error="!!errors[`BiographyBlock[${index}][first_title]`]"
                          :error-messages="errors[`BiographyBlock[${index}][first_title]`]"
                        />
                        <div style="margin-bottom: 15px;">
                          <span>First text (left)</span>
                          <ckeditor
                            v-model="contentItem.first_text"
                            :editor="editor"
                            :config="editorConfig"
                            @input="delete errors[`BiographyBlock[${index}][first_text]`]"
                          ></ckeditor>
                          <div v-if="errors[`BiographyBlock[${index}][first_text]`]" style="color: red;">{{ errors[`BiographyBlock[${index}][first_text]`][0] }}</div>
                        </div>
                        <va-card class="mb-3" title="First image (right)">
                          <a v-if="contentItem.first_image_existing.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.first_image_existing.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.first_image_existing.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            @input="delete errors[`BiographyBlock[${index}][first_image]`]"
                            v-model="contentItem.first_image_new"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]first_image`, 'builder_biography_first_image', `BiographyBlock[${index}][first_image]`, contentItem.first_image_new[0], 'common\\models\\builder\\BiographyBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`BiographyBlock[${index}][first_image]`]" style="color: red;">{{ errors[`BiographyBlock[${index}][first_image]`][0] }}</div>
                        </va-card>
                        <va-input
                          label="Second title (right)"
                          v-model="contentItem.second_title"
                          @input="delete errors[`BiographyBlock[${index}][second_title]`]"
                          :error="!!errors[`BiographyBlock[${index}][second_title]`]"
                          :error-messages="errors[`BiographyBlock[${index}][second_title]`]"
                        />
                        <div style="margin-bottom: 15px;">
                          <span>Second text (right)</span>
                          <ckeditor
                            v-model="contentItem.second_text"
                            :editor="editor"
                            :config="editorConfig"
                            @input="delete errors[`BiographyBlock[${index}][second_text]`]"
                          ></ckeditor>
                          <div v-if="errors[`BiographyBlock[${index}][second_text]`]" style="color: red;">{{ errors[`BiographyBlock[${index}][second_text]`][0] }}</div>
                        </div>
                        <va-card class="mb-3" title="Second image (left)">
                          <a v-if="contentItem.second_image_existing.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.second_image_existing.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.second_image_existing.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            @input="delete errors[`BiographyBlock[${index}][second_image]`]"
                            v-model="contentItem.second_image_new"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]second_image`, 'builder_biography_second_image', `BiographyBlock[${index}][second_image]`, contentItem.second_image_new[0], 'common\\models\\builder\\BiographyBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`BiographyBlock[${index}][second_image]`]" style="color: red;">{{ errors[`BiographyBlock[${index}][second_image]`][0] }}</div>
                        </va-card>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'simple-image-text'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error="!!errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`TwoTextBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Title *"
                          v-model="contentItem.title"
                          @input="delete errors[`SimpleImageTextBlock[${index}][title]`]"
                          :error="!!errors[`SimpleImageTextBlock[${index}][title]`]"
                          :error-messages="errors[`SimpleImageTextBlock[${index}][title]`]"
                        />
                        <va-input
                          label="Description *"
                          type="textarea"
                          v-model="contentItem.description"
                          @input="delete errors[`SimpleImageTextBlock[${index}][description]`]"
                          :error="!!errors[`SimpleImageTextBlock[${index}][description]`]"
                          :error-messages="errors[`SimpleImageTextBlock[${index}][description]`]"
                        />
                        <va-card class="mb-3" title="First image (right)">
                          <a v-if="contentItem.image_existing.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.image_existing.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.image_existing.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            v-model="contentItem.image_new"
                            @input="delete errors[`SimpleImageTextBlock[${index}][image]`]"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]image`, 'builder_simple_image_text_block_image', `SimpleImageTextBlock[${index}][image]`, contentItem.image_new[0], 'common\\models\\builder\\SimpleImageTextBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`SimpleImageTextBlock[${index}][image]`]" style="color: red;">{{ errors[`SimpleImageTextBlock[${index}][image]`][0] }}</div>
                        </va-card>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'interest-block'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error="!!errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`TwoTextBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Title"
                          v-model="contentItem.title"
                          @input="delete errors[`InterestBlock[${index}][title]`]"
                          :error="!!errors[`InterestBlock[${index}][title]`]"
                          :error-messages="errors[`InterestBlock[${index}][title]`]"
                        />
                        <va-input
                          label="Second title"
                          v-model="contentItem.second_title"
                          @input="delete errors[`InterestBlock[${index}][second_title]`]"
                          :error="!!errors[`InterestBlock[${index}][second_title]`]"
                          :error-messages="errors[`InterestBlock[${index}][second_title]`]"
                        />
                        <va-input
                          label="Left Title *"
                          v-model="contentItem.left_title"
                          @input="delete errors[`InterestBlock[${index}][left_title]`]"
                          :error="!!errors[`InterestBlock[${index}][left_title]`]"
                          :error-messages="errors[`InterestBlock[${index}][left_title]`]"
                        />
                        <va-input
                          label="Left Text *"
                          v-model="contentItem.left_text"
                          @input="delete errors[`InterestBlock[${index}][left_text]`]"
                          :error="!!errors[`InterestBlock[${index}][left_text]`]"
                          :error-messages="errors[`InterestBlock[${index}][left_text]`]"
                        />
                        <va-input
                          label="Center Title *"
                          v-model="contentItem.center_title"
                          @input="delete errors[`InterestBlock[${index}][center_title]`]"
                          :error="!!errors[`InterestBlock[${index}][center_title]`]"
                          :error-messages="errors[`InterestBlock[${index}][center_title]`]"
                        />
                        <va-input
                          label="Center Text *"
                          v-model="contentItem.center_text"
                          @input="delete errors[`InterestBlock[${index}][center_text]`]"
                          :error="!!errors[`InterestBlock[${index}][center_text]`]"
                          :error-messages="errors[`InterestBlock[${index}][center_text]`]"
                        />
                        <va-input
                          label="Right Title *"
                          v-model="contentItem.right_title"
                          @input="delete errors[`InterestBlock[${index}][right_title]`]"
                          :error="!!errors[`InterestBlock[${index}][right_title]`]"
                          :error-messages="errors[`InterestBlock[${index}][right_title]`]"
                        />
                        <va-input
                          label="Right Text *"
                          v-model="contentItem.right_text"
                          @input="delete errors[`InterestBlock[${index}][right_text]`]"
                          :error="!!errors[`InterestBlock[${index}][right_text]`]"
                          :error-messages="errors[`InterestBlock[${index}][right_text]`]"
                        />
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'video-block'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error="!!errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`TwoTextBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Video title *"
                          v-model="contentItem.video_title"
                          @input="delete errors[`VideoBlock[${index}][video_title]`]"
                          :error="!!errors[`VideoBlock[${index}][video_title]`]"
                          :error-messages="errors[`VideoBlock[${index}][video_title]`]"
                        />
                        <va-input
                          label="Youtube link *"
                          v-model="contentItem.video_src"
                          @input="delete errors[`VideoBlock[${index}][video_src]`]"
                          :messages="['YouTube only']"
                          :error="!!errors[`VideoBlock[${index}][video_src]`]"
                          :error-messages="errors[`VideoBlock[${index}][video_src]`]"
                        />
                        <va-card class="mb-3" title="Preview image">
                          <a v-if="contentItem.image_existing.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.image_existing.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.image_existing.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            v-model="contentItem.image_new"
                            @input="delete errors[`VideoBlock[${index}][image]`]"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]image`, 'builder_video_block_preview_image', `VideoBlock[${index}][image]`, contentItem.image_new[0], 'common\\models\\builder\\VideoBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`VideoBlock[${index}][image]`]" style="color: red;">{{ errors[`VideoBlock[${index}][image]`][0] }}</div>
                        </va-card>
                        <va-input
                          label="Start video title *"
                          v-model="contentItem.start_video_title"
                          @input="delete errors[`VideoBlock[${index}][start_video_title]`]"
                          :error="!!errors[`VideoBlock[${index}][start_video_title]`]"
                          :error-messages="errors[`VideoBlock[${index}][start_video_title]`]"
                        />
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'double-text'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error="!!errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`TwoTextBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Title *"
                          v-model="contentItem.title"
                          @input="delete errors[`DoubleTextBlock[${index}][title]`]"
                          :error="!!errors[`DoubleTextBlock[${index}][title]`]"
                          :error-messages="errors[`DoubleTextBlock[${index}][title]`]"
                        />
                        <va-input
                          label="Description left"
                          type="textarea"
                          v-model="contentItem.description_left"
                          @input="delete errors[`DoubleTextBlock[${index}][description_left]`]"
                          :error="!!errors[`DoubleTextBlock[${index}][description_left]`]"
                          :error-messages="errors[`DoubleTextBlock[${index}][description_left]`]"
                        />
                        <va-input
                          label="Description right"
                          type="textarea"
                          v-model="contentItem.description_right"
                          @input="delete errors[`DoubleTextBlock[${index}][description_right]`]"
                          :error="!!errors[`DoubleTextBlock[${index}][description_right]`]"
                          :error-messages="errors[`DoubleTextBlock[${index}][description_right]`]"
                        />
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'big-text-block'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error="!!errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`TwoTextBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          type="textarea"
                          label="Big Text *"
                          v-model="contentItem.text"
                          @input="delete errors[`BigTextBlock[${index}][text]`]"
                          :error="!!errors[`BigTextBlock[${index}][text]`]"
                          :error-messages="errors[`BigTextBlock[${index}][text]`]"
                        />
                        <va-card class="mb-3" title="Preview Image">
                          <a v-if="contentItem.image_existing.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.image_existing.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.image_existing.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            @input="delete errors[`BigTextBlock[${index}][image]`]"
                            v-model="contentItem.image_new"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]image`, 'builder_big_text_block_preview_image', `BigTextBlock[${index}][image]`, contentItem.image_new[0], 'common\\models\\builder\\BigTextBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`BigTextBlock[${index}][image]`]" style="color: red;">{{ errors[`BigTextBlock[${index}][image]`][0] }}</div>
                        </va-card>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'lviv-text'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`LvivTextBlock[${index}][anchor_title]`]"
                              :error="!!errors[`LvivTextBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`LvivTextBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Label"
                          v-model="contentItem.label"
                          @input="delete errors[`LvivTextBlock[${index}][label]`]"
                          :error="!!errors[`LvivTextBlock[${index}][label]`]"
                          :error-messages="errors[`LvivTextBlock[${index}][label]`]"
                        />
                        <va-input
                          label="Second Label"
                          v-model="contentItem.second_label"
                          @input="delete errors[`LvivTextBlock[${index}][second_label]`]"
                          :error="!!errors[`LvivTextBlock[${index}][second_label]`]"
                          :error-messages="errors[`LvivTextBlock[${index}][second_label]`]"
                        />
                        <va-input
                          label="Title"
                          v-model="contentItem.title"
                          @input="delete errors[`LvivTextBlock[${index}][title]`]"
                          :error="!!errors[`LvivTextBlock[${index}][title]`]"
                          :error-messages="errors[`LvivTextBlock[${index}][title]`]"
                        />
                        <div style="margin-bottom: 15px;">
                          <span>Description *</span>
                          <ckeditor
                            v-model="contentItem.description"
                            :editor="editor"
                            :config="editorConfig"
                            @input="delete errors[`LvivTextBlock[${index}][description]`]"
                          ></ckeditor>
                          <div v-if="errors[`LvivTextBlock[${index}][description]`]" style="color: red;">{{ errors[`LvivTextBlock[${index}][description]`][0] }}</div>
                        </div>
                        <va-select label="float *" :options="positioning" v-model="contentItem.float" no-clear></va-select>
                        <va-card class="mb-3" title="Image">
                          <a v-if="contentItem.imageExisting.src" style="display: block;" target="_blank" :href="adminUrl + contentItem.imageExisting.src"><img style="max-width: 400px;" :src="adminUrl + contentItem.imageExisting.src"></a>
                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            @input="delete errors[`LvivTextBlock[${index}][image]`]"
                            v-model="contentItem.imageNew"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]image`, 'builder_lviv_text_block_image', `LvivTextBlock[${index}][image]`, contentItem.imageNew[0], 'common\\models\\builder\\LvivTextBlock', contentItem.id, contentItem.target_sign)">Завантажити</va-button>
                          <div v-if="errors[`LvivTextBlock[${index}][image]`]" style="color: red;">{{ errors[`LvivTextBlock[${index}][image]`][0] }}</div>
                        </va-card>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'news'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`ArticlesListBlock[${index}][anchor_title]`]"
                              :error="!!errors[`ArticlesListBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`ArticlesListBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Title *"
                          v-model="contentItem.title"
                          @input="delete errors[`ArticlesListBlock[${index}][title]`]"
                          :error="!!errors[`ArticlesListBlock[${index}][title]`]"
                          :error-messages="errors[`ArticlesListBlock[${index}][title]`]"
                        />
                        <va-input
                          label="Subtitle"
                          v-model="contentItem.subtitle"
                          @input="delete errors[`ArticlesListBlock[${index}][subtitle]`]"
                          :error="!!errors[`ArticlesListBlock[${index}][subtitle]`]"
                          :error-messages="errors[`ArticlesListBlock[${index}][subtitle]`]"
                        />
                        <va-input
                          label="Read more label *"
                          v-model="contentItem.read_more_label"
                          @input="delete errors[`ArticlesListBlock[${index}][read_more_label]`]"
                          :error="!!errors[`ArticlesListBlock[${index}][read_more_label]`]"
                          :error-messages="errors[`ArticlesListBlock[${index}][read_more_label]`]"
                        />
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'simple-text'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error="!!errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`TwoTextBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Quote *"
                          type="textarea"
                          v-model="contentItem.title"
                          @input="delete errors[`SimpleTextBlock[${index}][title]`]"
                          :error="!!errors[`SimpleTextBlock[${index}][title]`]"
                          :error-messages="errors[`SimpleTextBlock[${index}][title]`]"
                        />
                        <va-input
                          label="Description"
                          type="textarea"
                          v-model="contentItem.description"
                          @input="delete errors[`SimpleTextBlock[${index}][description]`]"
                          :error="!!errors[`SimpleTextBlock[${index}][description]`]"
                          :error-messages="errors[`SimpleTextBlock[${index}][description]`]"
                        />
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                      <div v-else-if="contentItem.type === 'gallery-text'">
                        <div class="row">
                          <div class="flex xs2">
                            <va-toggle small label="Is anchor" v-model="contentItem.is_anchor"></va-toggle>
                          </div>
                          <div class="flex xs4">
                            <va-input
                              label="Anchor title"
                              v-model="contentItem.anchor_title"
                              @input="delete errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error="!!errors[`TwoTextBlock[${index}][anchor_title]`]"
                              :error-messages="errors[`TwoTextBlock[${index}][anchor_title]`]"
                            />
                          </div>
                        </div>
                        <va-input
                          label="Title *"
                          type="textarea"
                          v-model="contentItem.title"
                          @input="delete errors[`GalleryTextBlock[${index}][title]`]"
                          :error="!!errors[`GalleryTextBlock[${index}][title]`]"
                          :error-messages="errors[`GalleryTextBlock[${index}][title]`]"
                        />
                        <va-input
                          label="Description *"
                          type="textarea"
                          v-model="contentItem.description"
                          @input="delete errors[`GalleryTextBlock[${index}][description]`]"
                          :error="!!errors[`GalleryTextBlock[${index}][description]`]"
                          :error-messages="errors[`GalleryTextBlock[${index}][description]`]"
                        />
                        <va-input
                          label="Description right"
                          type="textarea"
                          v-model="contentItem.description_right"
                          @input="delete errors[`GalleryTextBlock[${index}][description_right]`]"
                          :error="!!errors[`GalleryTextBlock[${index}][description_right]`]"
                          :error-messages="errors[`GalleryTextBlock[${index}][description_right]`]"
                        />
                        <va-card class="mb-3" title="Images">
                          <div style="margin-bottom: 20px;" class="row">
                            <div class="flex xs4 text-center" v-for="(image, imageIndex) in contentItem.images_existing" :key="imageIndex">
                              <img style="max-width: 400px;" :src="adminUrl + image.url">
                              <div><va-button @click="deleteImage(index, imageIndex)">Видалити</va-button></div>
                            </div>
                          </div>

                          <span>Завантажити нове зображення</span>
                          <va-file-upload
                            type="gallery"
                            dropzone
                            @input="delete errors[`GalleryTextBlock[${index}][images]`]"
                            v-model="contentItem.image_new"
                            style="margin-bottom: 25px;"
                          />
                          <va-button @click="uploadImage(`[${index}]images`, 'builder_gallery_text_block_images', `GalleryTextBlock[${index}][images]`, contentItem.image_new[0], 'common\\models\\builder\\GalleryTextBlock', contentItem.id, contentItem.target_sign, contentItem.images_existing, index)">Завантажити</va-button>
                          <div v-if="errors[`GalleryTextBlock[${index}][images]`]" style="color: red;">{{ errors[`GalleryTextBlock[${index}][images]`][0] }}</div>
                        </va-card>
                        <va-button color="danger" small @click="removeInput(index)">Видалити</va-button>
                      </div>
                    </va-card>
                  </div>
                </draggable>
                <va-button
                  @click="submit()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="Meta Title"
                  v-model="metaTitle"
                />
                <va-input
                  label="Meta Description"
                  type="textarea"
                  v-model="metaDescription"
                />
                <va-input
                  label="Seo Title"
                  v-model="seoTitle"
                />
                <div style="margin-bottom: 15px;">
                  <span>Seo Text</span>
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  label="SEO OpenGraph Title"
                  v-model="seoOpenGraphTitle"
                />
                <va-input
                  type="textarea"
                  label="SEO OpenGraph Description"
                  v-model="seoOpenGraphDescription"
                />
                <va-card class="mb-3" title="SEO OpenGraph Image">
                  <div v-if="seoOpenGraphImageExisting">
                    <a style="display: block;" target="_blank" :href="seoOpenGraphImageExisting"><img style="max-width: 400px;" :src="seoOpenGraphImageExisting"></a>
                    <div><va-button small color="danger" @click="seoOpenGraphImageExisting = deleteSingleImage(seoOpenGraphImageId) ? '' : seoOpenGraphImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="seoOpenGraphImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(`image`, null, `SeoEntity[image]`, seoOpenGraphImage[0], 'common\\models\\SeoEntity', seoId, '')">Завантажити</va-button>
                </va-card>
                <va-button
                  @click="submit()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'
import draggable from 'vuedraggable'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
    draggable: draggable,
  },
  data () {
    return {
      label: '',
      second: '',
      sectionTitle: '',
      imageExisting: '',
      imageId: '',
      imageNew: [],
      description: '',

      content: [],
      blocks: [
        {
          id: 'simple-text',
          text: 'Blockquote',
        },
        {
          id: 'simple-image-text',
          text: 'Simple Image Text',
        },
        {
          id: 'interest-block',
          text: 'Interest Block',
        },
        {
          id: 'video-block',
          text: 'Video Block',
        },
        {
          id: 'double-text',
          text: 'Double Text',
        },
        {
          id: 'gallery-text',
          text: 'Three Images Gallery',
        },
        {
          id: 'big-text-block',
          text: 'Big Text Block',
        },
        {
          id: 'lviv-text',
          text: 'Chess Block',
        },
        {
          id: 'news',
          text: 'Articles List',
        },
        {
          id: 'biography',
          text: 'Biography Block',
        },
      ],
      selectedBlock: {
        id: 'simple-text',
        text: 'Blockquote',
      },
      positioning: ['left', 'right'],

      seoId: '',
      metaTitle: '',
      metaDescription: '',
      seoTitle: '',
      seoText: '',
      seoOpenGraphTitle: '',
      seoOpenGraphDescription: '',
      seoOpenGraphImage: [],
      seoOpenGraphImageId: '',
      seoOpenGraphImageExisting: '',

      errors: [],

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_SHKIL_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      tabTitles: ['Main', 'Content', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        section_title: this.sectionTitle,
        label: this.label,
        second_label: this.label,
        description: this.description,

        BiographyBlock: this.getBlocks('biography'),
        InterestBlock: this.getBlocks('interest-block'),
        SimpleTextBlock: this.getBlocks('simple-text'),
        VideoBlock: this.getBlocks('video-block'),
        GalleryTextBlock: this.getBlocks('gallery-text'),
        DoubleTextBlock: this.getBlocks('double-text'),
        ArticlesListBlock: this.getBlocks('news'),
        SimpleImageTextBlock: this.getBlocks('simple-image-text'),
        BigTextBlock: this.getBlocks('big-text-block'),
        LvivTextBlock: this.getBlocks('lviv-text'),

        SeoEntity: {
          title: this.metaTitle,
          description: this.metaDescription,
          seo_title: this.seoTitle,
          text: this.seoText,
          og_title: this.seoOpenGraphTitle,
          og_description: this.seoOpenGraphDescription,
        },
      }
    },
    adminUrl () {
      return process.env.VUE_APP_SHKIL_ADMIN
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.label || !!this.errors.second || !!this.errors.section_title || !!this.errors.image || !!this.errors.description
      } else if (tab === 1) {
        const errors = Object.keys(this.errors)
        return errors.some(i => {
          return i.includes('BiographyBlock') ||
            i.includes('InterestBlock') ||
            i.includes('SimpleTextBlock') ||
            i.includes('VideoBlock') ||
            i.includes('GalleryTextBlock') ||
            i.includes('DoubleTextBlock') ||
            i.includes('ArticlesListBlock') ||
            i.includes('SimpleImageTextBlock') ||
            i.includes('BigTextBlock') ||
            i.includes('LvivTextBlock')
        })
      } else if (tab === 2) {
        return false
      }
      return false
    },
    generateSign (length) {
      return Math.random().toString(20).substr(2, length)
    },
    getBlocks (name) {
      const blocks = this.content.filter(i => i.type === name)
      if (blocks.length) {
        const newArray = {}
        blocks.forEach(i => {
          const index = this.content.findIndex(j => {
            return i.id ? i.id === j.id : i.target_sign === j.target_sign
          })
          const newObj = Object.assign({}, i)
          newObj.is_anchor = i.is_anchor ? 1 : 0
          newArray[index] = newObj
        })
        return newArray
      }
      return []
    },
    uploadImage (attr, entityAttribute, param, file, model = 'backend\\modules\\article\\models\\Article', id = this.$attrs.id, sign = this.sign, images = null, contentIndex = null) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', model)
      formData.append('attribute', attr)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', entityAttribute === 'builder_gallery_text_block_images' ? '1' : '0')
      if (entityAttribute !== null) {
        formData.append('entity_attribute', entityAttribute)
      }

      if (id) {
        formData.append('id', id)
      }
      formData.append('sign', sign)
      axios.post(`${process.env.VUE_APP_SHKIL_API_URL}/admin/upload?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
          if (entityAttribute === 'builder_gallery_text_block_images') {
            const resp = JSON.parse(response.data)
            images.push({
              id: resp.imgId,
              url: resp.url,
            })
            this.content[contentIndex].image_new = []
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteSingleImage (id) {
      return axios.delete(`${process.env.VUE_APP_SHKIL_API_URL}/admin/upload/${id}?lang=${this.locale}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    deleteImage (index, imageIndex) {
      const imageId = this.content[index].images_existing[imageIndex].id
      axios.delete(`${process.env.VUE_APP_SHKIL_API_URL}/admin/upload/${imageId}?lang=${this.locale}`)
        .then(response => {
          this.showToast('Successfully deleted')
          this.content[index].images_existing.splice(imageIndex, 1)
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    addInput () {
      switch (this.selectedBlock.id) {
        case 'biography':
          this.content.push({
            type: 'biography',
            is_anchor: false,
            anchor_title: '',
            first_title: '',
            first_text: '',
            first_image_existing: '',
            first_image_new: [],
            second_title: '',
            second_text: '',
            second_image_existing: '',
            second_image_new: [],
            target_sign: this.generateSign(32),
          })
          break
        case 'interest-block':
          this.content.push({
            type: 'interest-block',
            is_anchor: false,
            anchor_title: '',
            title: '',
            second_title: '',
            left_title: '',
            left_text: '',
            center_title: '',
            center_text: '',
            right_title: '',
            right_text: '',
            target_sign: this.generateSign(32),
          })
          break
        case 'simple-text':
          this.content.push({
            type: 'simple-text',
            is_anchor: false,
            anchor_title: '',
            title: '',
            description: '',
            target_sign: this.generateSign(32),
          })
          break
        case 'video-block':
          this.content.push({
            type: 'video-block',
            is_anchor: false,
            anchor_title: '',
            video_title: '',
            video_src: '',
            image_existing: '',
            image_new: [],
            start_video_title: '',
            target_sign: this.generateSign(32),
          })
          break
        case 'gallery-text':
          this.content.push({
            type: 'gallery-text',
            is_anchor: false,
            anchor_title: '',
            title: '',
            description: '',
            description_right: '',
            images_new: [],
            images_existing: [],
            target_sign: this.generateSign(32),
          })
          break
        case 'double-text':
          this.content.push({
            type: 'double-text',
            is_anchor: false,
            anchor_title: '',
            title: '',
            description_left: '',
            description_right: '',
            target_sign: this.generateSign(32),
          })
          break
        case 'simple-image-text':
          this.content.push({
            type: 'simple-image-text',
            is_anchor: false,
            title: '',
            description: '',
            image_existing: '',
            image_new: [],
            target_sign: this.generateSign(32),
          })
          break
        case 'news':
          this.content.push({
            type: 'news',
            is_anchor: false,
            anchor_title: '',
            title: '',
            subtitle: '',
            read_more_label: '',
            target_sign: this.generateSign(32),
          })
          break
        case 'big-text-block':
          this.content.push({
            type: 'big-text-block',
            is_anchor: false,
            anchor_title: '',
            text: '',
            image_existing: '',
            image_new: [],
            target_sign: this.generateSign(32),
          })
          break
        case 'lviv-text':
          this.content.push({
            type: 'lviv-text',
            is_anchor: false,
            anchor_title: '',
            target_sign: this.generateSign(32),
            label: '',
            second_label: '',
            title: '',
            description: '',
            float: 'left',
            imageExisting: {
              src: null,
            },
            imageNew: [],
          })
          break
      }
    },
    removeInput (index) {
      this.content.splice(index, 1)
    },
    submit () {
      axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/founder-page?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/founder-page?lang=${this.locale}`)
        .then(response => {
          this.label = response.data.label
          this.second = response.data.second_label
          this.sectionTitle = response.data.section_title
          this.description = response.data.description
          this.imageExisting = response.data.image ? process.env.VUE_APP_SHKIL_ADMIN + response.data.image : ''
          this.imageId = response.data.imageId
          this.content = response.data.content
          this.content.forEach(i => {
            i.is_anchor = !!i.is_anchor
          })

          this.seoId = response.data.seo.id
          this.metaTitle = response.data.seo.title ? response.data.seo.title : ''
          this.metaDescription = response.data.seo.description ? response.data.seo.description : ''
          this.seoTitle = response.data.seo.seo_title ? response.data.seo.seo_title : ''
          this.seoText = response.data.seo.text ? response.data.seo.text : ''
          this.seoOpenGraphTitle = response.data.seo.og_title ? response.data.seo.og_title : ''
          this.seoOpenGraphDescription = response.data.seo.og_description ? response.data.seo.og_description : ''
          this.seoOpenGraphImageId = response.data.seo.imageId
          this.seoOpenGraphImageExisting = response.data.seo.image.src ? process.env.VUE_APP_SHKIL_ADMIN + response.data.seo.image.src : null
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}

.handle {
  font-size: 24px;
}
</style>
